body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

header, section, footer {
  padding: 20px;
  text-align: center;
}

header {
  background-color: #333;
  color: white;
}

section {
  border-bottom: 1px solid #ccc;
}

footer {
  background-color: #f1f1f1;
  padding: 10px;
}


