.services {
    background-color: #A3ACAE;
    text-align: center;
}

.container {
    max-width: 2000px;
    margin: 0 auto;
}

h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
}

.sections {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.section {
    flex: 1;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h3 {
    font-size: 1.75em;
    margin-bottom: 15px;
}

p {
    font-size: 1.1em;
    line-height: 1.6;
    color: #333;
}
